import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'MyNav' }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/views/auth/index'),
    meta: { title: '页面授权中……' }
  },
  {
    path: '/register',
    name: 'Register',
    meta: { title: '申请合伙人/销售顾问', wechatAuth: true },
    component: () => import('@/views/register/index')
  },
  {
    path: '/act',
    name: 'Act',
    redirect: { name: 'ActList' },
    component: () => import('@/views/act/index'),
    children: [
      {
        path: 'list',
        name: 'ActList',
        meta: { title: '活动列表', wechatAuth: true },
        component: () => import('@/views/act/list/index')
      }
    ]
  },
  {
    path: '/media',
    name: 'Media',
    redirect: { name: 'MediaList' },
    component: () => import('@/views/media/index'),
    children: [
      {
        path: 'list',
        name: 'MediaList',
        meta: { title: '动态列表', wechatAuth: true },
        component: () => import('@/views/media/list/index'),
      },
      {
        path: 'article',
        name: 'MediaArticle',
        meta: { title: '获客通', wechatAuth: true },
        component: () => import('@/views/media/article/index'),
      },
      {
        path: 'act',
        name: 'MediaAct',
        meta: { title: '获客通', wechatAuth: true },
        component: () => import('@/views/media/act/index'),
      },
      {
        path: 'picture',
        name: 'MediaPicture',
        meta: { title: '获客通', wechatAuth: true },
        component: () => import('@/views/media/picture/index'),
      },
      {
        path: 'video',
        name: 'MediaVideo',
        meta: { title: '获客通', wechatAuth: true },
        component: () => import('@/views/media/video/index'),
      }
    ]
  },
  {
    path: '/my',
    name: 'My',
    redirect: { name: 'MyList' },
    component: () => import('@/views/my/index'),
    children: [
      {
        path: 'nav',
        name: 'MyNav',
        meta: { title: '内部导航' },
        component: () => import('@/views/my/nav/index'),
      },
      {
        path: 'list',
        name: 'MyList',
        meta: { title: '我的' },
        component: () => import('@/views/my/list/index'),
      },
      {
        path: 'info',
        name: 'MyInfo',
        meta: { title: '我的信息' },
        component: () => import('@/views/my/info/index'),
      },
      {
        path: 'order',
        name: 'MyOrder',
        meta: { title: '我的订单' },
        component: () => import('@/views/my/order/index'),
      },
      {
        path: 'address',
        name: 'MyAddress',
        meta: { title: '我的地址' },
        component: () => import('@/views/my/address/index'),
      },
      {
        path: 'act',
        name: 'MyAct',
        meta: { title: '我的活动' },
        component: () => import('@/views/my/act/index'),
      },
      {
        path: 'sign',
        name: 'MySign',
        meta: { title: '活动核销' },
        component: () => import('@/views/my/sign/index'),
      },
      {
        path: 'cardedit',
        name: 'MyCardEdit',
        meta: { title: '名片更新' },
        component: () => import('@/views/my/cardedit/index'),
      },
      {
        path: 'kv',
        name: 'Kv',
        meta: { title: '名片海报' },
        component: () => import('@/views/my/kv/index'),
      },
      {
        path: 'kvedit',
        name: 'KvEdit',
        meta: { title: '海报设计' },
        component: () => import('@/views/my/kv/edit'),
      }
    ]
  },
  {
    path: '/cash',
    name: 'Cash',
    redirect: { name: 'CashList' },
    component: () => import('@/views/cash/index'),
    children: [
      {
        path: 'list',
        name: 'CashList',
        meta: { title: '我的拥金' },
        component: () => import('@/views/cash/list/index'),
      },
      {
        path: 'get',
        name: 'CashGet',
        meta: { title: '提现' },
        component: () => import('@/views/cash/get/index'),
      },
      {
        path: 'record',
        name: 'CashRecord',
        meta: { title: '提现记录' },
        component: () => import('@/views/cash/record/index'),
      },
      {
        path: 'card',
        name: 'CashCard',
        meta: { title: '银行卡' },
        component: () => import('@/views/cash/card/index'),
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
