import axios from 'axios'
// import store from '@/store'
import { Notify } from 'vant'
// import wechatAuth from '../utils/wechatAuth'
// import qs from 'qs'
import { getSite } from '@/utils/auth'
import Cookies from 'js-cookie'

axios.defaults.timeout = 5000
axios.defaults.baseURL = 'https://api.hk.zhongheinfo.com'

axios.interceptors.request.use(
  config => {
    config.headers['site'] = getSite().site
    config.headers['client-token'] = Cookies.get('x-auth-token')
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    console.log(error)
    Notify({ type: 'danger', message: error.data.msg });
    return Promise.reject(error)
  }
)
export default axios
