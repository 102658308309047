const qs = require('qs')
import { getSite } from '@/utils/auth'
// const SCOPES = ['snsapi_base', 'snsapi_userinfo']

class VueWechatAuthPlugin {
  constructor() {
    this.oauthUrl = 'https://api.hk.zhongheinfo.com/wxc/wx/oauth/site/' + getSite().site
    this.redirect_uri = null
    this.from_id = null //来源客户ID
  }

  authUrl () {
    let urlParams = qs.parse(window.location.href.split('?')[1])
    this.redirect_uri = this.processUrl()
    let url = `${this.oauthUrl}?redirect=${this.redirect_uri}`
    if (urlParams.from_id) {
      url =  `${url}&from_id=${urlParams.from_id}`
    }

    if (urlParams.article_id) {
      url =  `${url}&from_article_id=${urlParams.article_id}`
    }

    url =  `${url}&platform=3`

    return url
  }

  processUrl () {
    const url = window.location.href
    let redirectUrl = url
    const urlParams = qs.parse(url.split('?')[1])
    if (urlParams.token) {
      delete urlParams.token
    }

    if (urlParams.from_id) {
      delete urlParams.from_id
    }
    if (urlParams.msg) {
      delete urlParams.msg
    }
    if (urlParams.code) {
      delete urlParams.code
    }
    const query = qs.stringify(urlParams)
    redirectUrl = `${url.split('?')[0]}?${query}`

    return this.bin2hex(`${location.origin}/#/auth?origin_url=${encodeURIComponent(redirectUrl)}`)
    // return this.bin2hex(redirectUrl)
  }

  bin2hex (str) {
    if (str === "") {
      return "";
    }
    var arr = [];
    // arr.push("0x");
    for (var i = 0; i < str.length; i++) {
      arr.push(str.charCodeAt(i).toString(16));
    }
    return arr.join('');
  }
}
const vueWechatAuthPlugin = new VueWechatAuthPlugin()
export default vueWechatAuthPlugin
