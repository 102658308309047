// import qs from 'qs'
import router from '@/router'
import http from '@/utils/http.js'
// import store from '@/store'
import wechatAuth from '@/utils/wechatAuth'
import Cookies from 'js-cookie'
// import { getToken, setToken, setSite, removeToken } from '@/utils/auth'
// import { setToken } from '@/utils/auth'

// 设置APPID
const whiteList = ['/404', '/auth']
router.beforeEach(async (to, from, next) => {
  // 在白名单，直接进入
  if (whiteList.indexOf(to.path) !== -1) {
    return next()
  }

  if (Cookies.get('x-auth-token')) {
    // alert('有本地token')
    http.post('/wxc/client/detail').then(res => {
      if (res.code !== 200) {
        // alert('有本地token，但token不对')
        Cookies.remove('x-auth-site')
        window.location.href = wechatAuth.authUrl()
      } else {
        // alert('有本地token，也是正确')
        Cookies.set('client_id', res.data.client_id)
        Cookies.set('realname', res.data.realname)
        Cookies.set('wechat_avatar', res.data.wechat_avatar)
        Cookies.set('wechat_nickname', res.data.wechat_nickname)
        Cookies.set('client_type', res.data.client_type)
      }
    })

    next()
  } else {
    Cookies.remove('x-auth-site')
    window.location.href = wechatAuth.authUrl()
  }
})