import Cookies from 'js-cookie'

export function getToken() {
  return Cookies.get('x-auth-token')
}

export function setToken(token) {
  return Cookies.set('x-auth-token', token)
}

export function setSite(site) {
  return Cookies.set('x-auth-site', site)
}

export function removeToken() {
  return Cookies.remove('x-auth-site')
}

const siteConfig = {
  'default': {
    site: 'default',
    companyName: '苏州众和'
  },
  'nbzhz.wechat.hk.zhongheinfo.com': {
    site: 'nbzhz',
    companyName: '宁波众致'
  },
  'zhdc.wechat.hk.zhongheinfo.com': {
    site: 'zhdc',
    companyName: '众和雪铁龙'
  },
  'hgxz.wechat.hk.zhongheinfo.com': {
    site: 'hgxz',
    companyName: '黄冈鑫众'
  },
  'zhzsls.wechat.hk.zhongheinfo.com': {
    site: 'zhzsls',
    companyName: '致众赛力斯'
  },
  'whql.wechat.hk.zhongheinfo.com': {
    site: 'whql',
    companyName: '武汉麒麟众鑫汽车'
  },
  'zhlsh.wechat.hk.zhongheinfo.com': {
    site: 'zhlsh',
    companyName: '苏州路尚'
  }
}

export function getSite() {
  if (siteConfig[location.host]) {
    return siteConfig[location.host]
  } else {
    return siteConfig['default']
  }
}
